@import './src/scss/variables';
@import './src/scss/app';
@import './src/scss/moment-picker';

.moment-picker.inline {
  display: none;

  .moment-picker-container {
    width: 17em;
  }

  .month-view {
    th::first-letter {
      text-transform: uppercase;
    }
  }

  .day-view {
    thead {
      display: none;
    }
  }
}

.open-submenu {
  .moment-picker.inline {
    position: absolute;
    display: block;
  }
}

.timer__buttons {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  justify-content: center;

  .timer__button {
    margin-right: 1rem;
  }
}

.timeparts {
  display: flex;
  justify-content: center;
  margin: 0 0 2rem;
  &__item {
    text-align: center;
    display: flex;
    align-items: center;
    //&:not(:last-child):after {
    //  content: ":";
    //  font-size: 36px;
    //  margin: 0 0.5rem;
    //}
    &-day:after {
      color: red
    }
    &-hour:after {
      color: orange;
    }
    &-minute:after, &-second:before {
      color: #7c5cad
    }
    &-second:after {
      color: blue
    }
    &-ms:before {
      color: gray;
    }
    &:not(:nth-last-child(2)):not(&-ms):after {
      content: ":";
      font-size: 36px;
      margin: 0 0.5rem;
    }
    &-ms:before {
      content: ".";
      font-size: 36px;
      margin: 0 0.5rem;
    }
    &:last-child:not(&-ms):after {
      content: "" !important;
    }
    &:last-child:not(&-ms):before {
      content: ":";
      font-size: 36px;
      margin: 0 0.5rem;
    }
    &-count {
      min-width: 120px;
      font-size: 96px;
      line-height: 1;
      margin: 0.5rem;
      font-weight: 600;
    }
    &-format {
      font-size: 0.725rem;
      text-transform: uppercase;
    }
  }
}