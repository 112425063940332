@import './src/scss/variables';

.moment-picker-input {
  cursor: pointer;
}

.moment-picker {
  position: absolute;
  z-index: 1060;
}

.moment-picker .moment-picker-container {
  min-width: 15em;
  background: $color2;
  padding: 1rem;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  position: absolute;
  margin-top: .5rem;
}

.moment-picker .moment-picker-container:before,
.moment-picker .moment-picker-container:after {
  display: none;
}

.moment-picker.inline {
  display: block;
  position: relative;
}

.moment-picker.inline .moment-picker-container {
  position: relative;
  margin: 0;
}

.moment-picker.inline .moment-picker-container:before,
.moment-picker.inline .moment-picker-container:after {
  content: none;
}

.moment-picker.top .moment-picker-container {
  bottom: 100%;
  margin-top: auto;
  margin-bottom: 4px;
}

.moment-picker.top .moment-picker-container:before,
.moment-picker.top .moment-picker-container:after {
  border: 8px solid transparent;
  border-bottom: none;
  top: auto;
  bottom: -9px;
}

.moment-picker.top .moment-picker-container:before {
  border-top-color: #f0f3f4;
  border-width: 9px;
}

.moment-picker.top .moment-picker-container:after {
  border-top-color: $color2;
  margin-top: auto;
  margin-bottom: 1px;
}

.moment-picker.right .moment-picker-container {
  right: 0;
  margin-left: auto;
  margin-right: -0.5em;
}

.moment-picker.right .moment-picker-container:before,
.moment-picker.right .moment-picker-container:after {
  left: auto;
  right: 15px;
}

.moment-picker.right .moment-picker-container:after {
  margin-left: auto;
  margin-right: 1px;
}

.moment-picker table {
  border-collapse: collapse;
  border-spacing: 0;
  min-width: 100%;
  table-layout: fixed;
}

.moment-picker th {
  font-weight: bold;
}

.moment-picker th:first-child,
.moment-picker th:last-child {
  width: 2em;
}

.moment-picker th,
.moment-picker td {
  padding: .25rem 0;
  text-align: center;
  min-width: 2em;
  cursor: pointer;
  border-radius: $border-radius;
}

.moment-picker th:hover,
.moment-picker td:hover {
  background-color: transparent;
  opacity: $opacity;
}

.moment-picker th.disabled,
.moment-picker td.disabled,
.moment-picker th.disabled:hover,
.moment-picker td.disabled:hover {
  opacity: $opacity2;
  background: none;
  cursor: pointer;
}

.moment-picker td.today {
  background: $color6;
  color: $color;
}

.moment-picker td.selected {
  color: $color;
  background-color: $color6;
}

.moment-picker td.highlighted {
  background-image: -webkit-radial-gradient(transparent, rgba(0, 0, 0, 0.15));
  background-image: -moz-radial-gradient(transparent, rgba(0, 0, 0, 0.15));
  background-image: -o-radial-gradient(transparent, rgba(0, 0, 0, 0.15));
  background-image: radial-gradient(transparent, rgba(0, 0, 0, 0.15));
}

.moment-picker .decade-view td,
.moment-picker .year-view td {
  height: 3.4em;
}

.moment-picker .month-view .moment-picker-specific-views th {
  background: none;
  cursor: default;
  opacity: 1;
}

.moment-picker .moment-picker-specific-views td {
  vertical-align: middle;
}

.moment-picker .month-view td {
  width: 1.4285714286em;
}

.moment-picker .day-view td,
.moment-picker .hour-view td {
  height: 2.3333333333em;
}

.moment-picker .minute-view td {
  height: 1.8em;
}