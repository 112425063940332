@import 'variables';

button[disabled], button:disabled {
  opacity: 1;
  cursor: not-allowed;
}

.app {
  // do not write here

  &__container {
    text-align: center;
  }
}

.widget {
  display: flex;
  justify-content: center;
  flex-direction: column;
  // do not write here

	&__flex {
		display: flex;
	  flex-direction: column;
	  gap: 1rem;

	  &_small {
	    gap: .5rem;
	  }

	  &_row {
	    flex-direction: row;
	  }
	}

  &__field {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    //border-radius: $border-radius;
    background: $color6;

    textarea {
      padding: .5rem;
      resize: none;
      width: 100%;
      height: $max-height;
      display: block;
      border: 1px solid;
      font-family: $font-family;
      font-size: $font-size;
      color: $color;
      //border-radius: $border-radius;
      background: $color6;
      //box-shadow: 0 0 0 .25rem #f3f4f7;

			&:focus {
				//outline: 2px solid #fc0;
			}
    }

		&-group {
			display: flex;
	    align-items: center;
	    flex-direction: column;
	    gap: .5rem;
    }

    &_transparent {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUAQMAAAC3R49OAAAABlBMVEXv7+////9mUzfqAAAAFElEQVQIW2NksN/ISAz+f9CBGAwAxtEddZlnB4IAAAAASUVORK5CYII=);
      height: 550px;
      padding: 0;
      width: 100%;
      object-fit: cover;
    }

		label {
	    cursor: pointer;
	    //font-weight: 600;
		}
  }

  &__input {
    padding: 1rem;
    background: $color6;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: calc(#{$widget-width} + 2rem);
    margin: auto;
    gap: 1rem;
    //border-radius: $border-radius;

    &-title {
      font-weight: 400;
    }

    &-group {
			display: flex;
	    align-items: center;
	    flex-direction: column;
	    gap: .5rem;
	    max-width: $widget-width;

      &_help {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: .5rem 1rem;

        button {
					color: $color;
					@include text-decoration;
        }
      }

      &_control {
        display: flex;
        justify-content: center;
        gap: 1rem;
      }
    }

    &-readonly {
      label {
        cursor: default !important;
      }

      input {
        cursor: default;
        outline: none !important;
      }
    }

    label {
      cursor: pointer;
      //font-weight: 600;
    }

    &.ng-scope {
      display: flex;
      align-items: center;
    }

    &-number {
      padding: 0 .5rem;
      height: 48px;
      width: $widget-width;
      border: 1px solid;
      font-family: $font-family;
      font-size: $font-size;
      text-align: center;
      color: $color;
      background: $color6;
      //font-weight: 600;
      //border-radius: $border-radius;
      //box-shadow: 0 0 0 .25rem #f3f4f7;

      &:focus {
				//outline: 2px solid #fc0;
			}
    }

		&-text {
      padding: .5rem;
      resize: none;
      width: 100%;
      min-width: $widget-width;
      height: $max-height;
      display: block;
      border: 1px solid;
      font-family: $font-family;
      font-size: $font-size2;
      background: $color6;
      //border-radius: $border-radius;
      //box-shadow: 0 0 0 .25rem #f3f4f7;

      &:focus {
				//outline: 2px solid #fc0;
			}
    }

    &-image {
      padding: 0 1rem;
      //background: $color6;
      max-width: 500px;
      display: block;
      //mix-blend-mode: luminosity;
      //border-radius: $border-radius;
    }
  }

  &__upload-image {
    cursor: pointer;
    border: 1px dashed;
    height: $max-height;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;

    &:hover {
      background: $color6;
    }
  }

  &__toggle {
    input {
      display: none;
    }

    label {
			color: $color;
			//position: relative;
			@include text-decoration;
      font-weight: 400;

      &:hover {
        opacity: $opacity;
      }
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: baseline;
    flex-wrap: wrap;
    gap: 1rem;
    user-select: none;

    button {
      font-weight: 600;
      background: transparent;
      font-family: $font-family;
      font-size: $font-size;
      color: $color;
      display: flex;
      align-items: center;
      gap: .5rem;

      &:hover {
        opacity: $opacity;
      }
    }

    a {
      display: flex;
      align-items: center;
      gap: .5rem;
      font-weight: 600;
    }

    &_top {
      margin: 1rem 0 0;
    }
  }

  &__group {
    // do not write here
  }

  &__options {
    // do not write here

    &-list {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $color;
      flex-flow: wrap;
      gap: 1rem;

      li {
				display: flex;
		    align-items: center;
		    gap: .5rem;
      }

      .widget__input-number {
        width: auto;
        height: auto;
        border: 0;
        border-bottom: 1px solid;
		    background: none;
		    box-shadow: none;

		    &:focus {
		      outline: none;
		    }
      }
    }

    label {
      cursor: pointer;
      //font-weight: 600;
    }
  }

  &__result {
    display: flex;
    justify-content: center;
    // do not write here

    b, strong {
      font-weight: 400;
      color: $color;
      text-decoration-line: none;
    }

    &_block {
      display: block;
      width: 100%;
    }

    &-field {
	    display: flex;
	    flex-direction: column;
	    width: 100%;

      textarea {
	      padding: .5rem;
	      resize: none;
	      width: 100%;
	      height: $max-height;
	      display: block;
	      border: 1px solid $color;
	      font-family: $font-family;
	      font-size: $font-size;
	      color: $color;
	      //border-radius: $border-radius;
	      background: $color2;
      }

	    &_readonly {
	    }
    }

    &-wrap {
			margin: 2rem 0 0;
			//min-width: calc(#{$widget-width} + 2rem);
	    display: flex;
	    flex-direction: column;
	    align-items: center;
	    gap: 2rem;

	    &_small {
	      gap: 1rem;
	      width: 100%;
	    }
    }

    &-text {
      background: $color2;
      min-width: $min-width;

      button {
				color: $color;
				@include text-decoration;
        font-weight: 600;
      }

      &_error {
        color: $color;
      }
    }

    &-chart {
      margin: auto;
      background: $color2;
      //border: 1px solid;
      //padding: .5rem;

      &_top {
        margin-top: 1rem;
      }

      &_pie {
        display: inline-block;
      }

      &_line {
        //width: 640px;
      }
    }

    &-table {
      margin: auto;
      text-align: left;
      background: $color2;
      border: 1px solid;
      min-width: $min-width;

      caption {
        padding: .5rem;
        border-bottom: 1px solid;
      }

      th {
        padding: .5rem;
        font-weight: 400;

        .fa-light {
          margin-right: .5rem;
				}
      }

      tr:last-of-type td {
        border-bottom: none;
			}

      td {
        padding: .5rem;
        border: 1px solid;

        &:first-of-type {
          border-left: 0;
        }

        &:last-of-type {
          border-right: 0;
        }
      }

      &_info {
        opacity: $opacity2;
      }

      &_inline {
        display: inline-block;
        overflow: auto;
      }

      button {
				display: inline-flex;
				align-items: center;
				//color: $color;
				//font-weight: 600;
				color: #006cca;
				@include text-decoration;
      }

      .fa-arrow-right-long {
        margin: 0 .5rem;
      }
    }

    &-tab {
      text-align: center;

      & > input[type="radio"] {
        display: none;
      }

      & > input[type="radio"]:checked + label {
        cursor: default;
        opacity: 1;
        text-decoration: none;
      }

      & > div {
        display: none;
        padding-top: 1rem;
      }

      & > label {
        cursor: pointer;
        margin: 0 .5rem;
        color: $color;
				@include text-decoration;

				&:hover {
					opacity: $opacity;
				}
      }
    }
  }

  &__submenu {
    &-list {
      display: flex;
      gap: 1rem;

       button {
				span {
					color: $color;
					@include text-decoration;
        }
      }

      li {
        &.has-submenu {
          position: relative;
          display: inline-block;

          &.open-submenu {
            .widget__submenu-list_inner,
            .options {
              display: block;
            }

            .widget__submenu-list_inner {
              right: 50%;
              transform: translate(50%, 0);
              display: flex;
              gap: .5rem;
              flex-direction: column;
            }
          }
        }
      }

      &_inner,
      .options {
        display: none;

        li {
          display: flex;

//           label {
//             cursor: pointer;
//           }
        }

        button {
          white-space: nowrap;
          word-break: keep-all;
        }
      }

      &_overflow {
        overflow: auto;
        max-height: 350px;
      }
    }
  }
}

.full {
	width: 100%;
}

#tab-link-1:checked ~ #tab-1,
#tab-link-2:checked ~ #tab-2,
#tab-link-3:checked ~ #tab-3,
#tab-link-4:checked ~ #tab-4 {
  display: block;
}

input.ng-invalid-number,
input.ng-invalid-pattern,
input.ng-invalid-required.ng-dirty,
input.ng-invalid-max,
input.ng-invalid-min,
textarea.ng-invalid-pattern,
textarea.ng-invalid-comma-length,
textarea.ng-invalid-required.ng-dirty,
input.ng-invalid.ng-dirty {
  //outline: 2px solid $color11;
  //border: 1px solid $color11;
}

input::placeholder,
textarea::placeholder {
  color: rgba($color, .2);
}

.selected {
	background: #fafad2;
}

.lowercase {
	text-transform: lowercase;
}

.uppercase {
	text-transform: uppercase;
}

.capitalize::first-letter {
	text-transform: capitalize;
}

sup {
  vertical-align: super;
}

sub {
  vertical-align: sub;
  margin: 0 .125rem;
}